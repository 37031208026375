
            @import "@/assets/sass/vars.portal.scss";
          

























































































































.dash-card {
    position: relative;

    hr.hr {
        border-top: none;
        margin-bottom: 0px;
        margin-top: 14px;
    }

    &.has-navis {
        margin-bottom: 40px;
    }

    &__navis {
        position: absolute;
        bottom: -35px;
        left: 0;
        right: 0;
        color: white;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        text-align: center;
    }

    &.active {
        //background-color: #3a7374;
        .dash-card__footer, .card-title, span, .dash-card__icon {
            color: white !important;
        }
    }

    &__progress {
        margin-top: 11px;
    }

    & .card-body {
        padding: 18px;
    }

    &__default {
        font-size: 29px;
    }

    &__footer {
        margin-top: 15px;
        margin-bottom: 0px;
        font-size: 12px;
        font-weight: 600;
        color: $primary;
    }

    &__icon {
        font-size: 24px;
    }
}
